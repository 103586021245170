import React, {useEffect, useState} from "react";
import PrivateRoute from "./components/PrivateRoute";
import history from "./utils/history";
import {Router, Switch} from "react-router-dom";
import Home from "./views/Home";
import Settings from "./views/Settings";
import EditWriting from "./views/Writings/EditWriting";
import ShowWritings from "./views/Writings/ShowWritings";
import Admin from "./views/Admin";
import Achievements from "./views/Achievements";
import ROUTES from "./utils/routes";
import axios from "axios";
import {useAuth0} from "./react-auth0-spa";

export const AppRouter = () => {
    const [isBeta, setIsBeta] = useState(false);
    const [userSplitTestingHexDigit, setUserSplitTestingHexDigit] = useState('');
    const {user, getTokenSilently} = useAuth0();

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    useEffect(() => {
        getTokenSilently()
            .then(token =>
                axios({
                    url: `${process.env.REACT_APP_API_URL}/user`,
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Visit_Id: user.visit_id || ""
                    },
                    cancelToken: source.token
                })
            )
            .then(response => {
                setIsBeta(!!response.data.beta);
                let splitTestingHexDigit;
                let splitTestingHexDigitReason;
                if (response.data.splitTestingHexDigit) {
                    splitTestingHexDigit = response.data.splitTestingHexDigit;
                    splitTestingHexDigitReason = 'manual override';
                } else if (new Date(response.data.createdAt) < new Date('2021-04-29 00:00')) {
                    splitTestingHexDigit = "f";
                    splitTestingHexDigitReason = 'user signup date';
                } else if (new Date(response.data.createdAt) < new Date('2021-08-01 00:00')) {
                    splitTestingHexDigit = response.data.sub.toString().substring(response.data.sub.length - 1, response.data.sub.length)
                    splitTestingHexDigitReason = 'user sub value';
                } else {
                    splitTestingHexDigit = response.data._id.toString().substring(response.data._id.length - 1, response.data._id.length)
                    splitTestingHexDigitReason = 'user id value';
                }
                setUserSplitTestingHexDigit(splitTestingHexDigit);
                if (process.env.NODE_ENV === 'development') {
                    console.log(`Split hex digit: ${splitTestingHexDigit} due to ${splitTestingHexDigitReason}`);
                }
            })
            .catch(error => {
                console.log(error)
            })
    })

    return (
        <Router history={history}>
            <Switch>
                <PrivateRoute isBeta={isBeta} userSplitTestingHexDigit={userSplitTestingHexDigit} path={ROUTES.HOME}
                              exact component={Home}/>
                <PrivateRoute isBeta={isBeta} userSplitTestingHexDigit={userSplitTestingHexDigit}
                              path={ROUTES.WRITING_NEW} component={EditWriting}/>
                <PrivateRoute isBeta={isBeta} userSplitTestingHexDigit={userSplitTestingHexDigit}
                              path={ROUTES.WRITING_EDIT} component={EditWriting}/>
                <PrivateRoute isBeta={isBeta} userSplitTestingHexDigit={userSplitTestingHexDigit} path={ROUTES.WRITINGS}
                              component={ShowWritings}/>
                <PrivateRoute isBeta={isBeta} userSplitTestingHexDigit={userSplitTestingHexDigit} path={ROUTES.SETTINGS}
                              component={Settings}/>
                <PrivateRoute isBeta={isBeta} userSplitTestingHexDigit={userSplitTestingHexDigit} path={ROUTES.ADMIN}
                              component={Admin}/>
                <PrivateRoute isBeta={isBeta} userSplitTestingHexDigit={userSplitTestingHexDigit}
                              path={ROUTES.ACHIVEMENTS} component={Achievements}/>
            </Switch>
        </Router>
    );
};
