// Define badges threshold.
let WRITING_DAYS = 'days';
let TOTAL_WORDS = 'words';
let STREAK = 'streak';
let DAYS_IN_LAST_7 = 'week';
let SPECIAL = 'special';

let thresholds = {}
thresholds[TOTAL_WORDS] = [250, 1000, 5000, 10000, 20000, 50000, 100000, 500000, 1000000, 2000000, 3000000]
thresholds[SPECIAL] = ['birthday', 'early_bird', 'new_year', 'night_owl', 'writers_day']
thresholds[WRITING_DAYS] = [1, 5, 10, 30, 60, 100, 365, 500, 1000]
thresholds[STREAK] = [5, 10, 20, 30, 'max']
thresholds[DAYS_IN_LAST_7] = [3, 4, 5, 6, 7]

export const ACHV_TITLES = {}
ACHV_TITLES[WRITING_DAYS] = 'Days Milestones'
ACHV_TITLES[TOTAL_WORDS] = 'Words Milestones'
ACHV_TITLES[STREAK] = 'Streaks'
ACHV_TITLES[DAYS_IN_LAST_7] = 'Writing Days in a Week'
ACHV_TITLES[SPECIAL] = 'Special Trophies'

// Define badges name.
export const BADGES = [
  WRITING_DAYS,
  TOTAL_WORDS,
  STREAK,
  DAYS_IN_LAST_7
]

export const ACHIEVEMENT_TEXTS = {
  special_early_bird: {
    title: 'Early Bird',
    achieved_content: 'You own the morning! You wrote five times between 5am and 8am.',
    not_achieved_content: 'Write five times between 5am and 8am to earn this achievement.',
    color: 'orange',
    share_text: 'I\'m an early bird. I wrote five times between 5am and 8am with WriteNext.'
  },
  special_night_owl: {
    title: 'Night Owl',
    achieved_content: 'You own the night! You wrote five times between 9pm and 12am.',
    not_achieved_content: 'Write five times between 9pm and 12am to earn this achievement.',
    color: 'blue',
    share_text: 'I\'m a night owl. I wrote five times between 9pm and 12am with WriteNext.'
  },
  special_birthday: {
    title: 'Happy Birthday',
    achieved_content: 'Write on your birthday to earn this achievement.',
    not_achieved_content: 'Consistency is key! You wrote on your birthday.',
    color: 'orange',
    share_text: 'Write every day! I wrote on my birthday with WriteNext.'
  },
  special_new_year: {
    title: 'New Year, More Words',
    achieved_content: 'Happy New Year! You wrote on New Year\'s Day.',
    not_achieved_content: 'Write on New Year’s Day to earn this achievement.',
    color: 'orange',
    share_text: 'Write Every day! I wrote on New Year\'s Day with WriteNext.'
  },
  special_writers_day: {
    title: 'Writer\'s Day',
    achieved_content: 'Congratulations. You wrote on International Writer\'s Day.',
    not_achieved_content: 'Write on International Writer\'s Day to earn this achievement.',
    color: 'orange',
    share_text: 'Happy Writer\'s Day! I wrote on Internation Writer\'s Day with WriteNext.'
  },

  /*
   * days milestones
   */
  days_1: {
    title: 'days milestone',
    achieved_content: 'First writing day in the books!',
    not_achieved_content: 'Write for the first time to earn this achievement.',
    color: 'orange',
    share_text: 'I wrote for the 1st day with WriteNext.'
  },
  days_5: {
    title: 'days milestone',
    achieved_content: 'Don\'t stop! You have five writing days.',
    not_achieved_content: 'Write in five different days to earn this achievement.',
    color: 'blue',
    share_text: 'I wrote for the 5th day with WriteNext.'
  },
  days_10: {
    title: 'days milestone',
    achieved_content: 'Double digits! You have ten writing days.',
    not_achieved_content: 'Write in ten different days to earn this achievement.',
    color: 'blue',
    share_text: 'I wrote for the 10th day with WriteNext.'
  },
  days_30: {
    title: 'days milestone',
    achieved_content: 'Keep it going! You have thirty writing days.',
    not_achieved_content: 'Write in thirty different days to earn this achievement.',
    color: 'blue',
    share_text: 'I wrote for the 30th day with WriteNext.'
  },
  days_60: {
    title: 'days milestone',
    achieved_content: 'Great job! You have sixty writing days.',
    not_achieved_content: 'Write in sixty different days to earn this achievement.',
    color: 'blue',
    share_text: 'I wrote for the 60th day with WriteNext.'
  },
  days_100: {
    title: 'days milestone',
    achieved_content: 'Triple digits! You have one hundred writing days.',
    not_achieved_content: 'Write in one hundred different days to earn this achievement.',
    color: 'blue',
    share_text: 'I wrote for the 100th day with WriteNext.'
  },
  days_365: {
    title: 'days milestone',
    achieved_content: 'One year! You have three hundred sixty-five writing days.',
    not_achieved_content: 'Write in three hundred sixty-five different days to earn this achievement.',
    color: 'blue',
    share_text: 'I wrote for the 365th day with WriteNext.'
  },
  days_500: {
    title: 'days milestone',
    achieved_content: 'Very impressive! You have five hundred writing days.',
    not_achieved_content: 'Write in five hundred different days to earn this achievement.',
    color: 'blue',
    share_text: 'I wrote for the 500th day with WriteNext.'
  },
  days_1000: {
    title: 'days milestone',
    achieved_content: 'Quadruple digits! You have one thousand writing days.',
    not_achieved_content: 'Write in one thousand different days to earn this achievement.',
    color: 'blue',
    share_text: 'I wrote for the 1000nd day with WriteNext.'
  },


  /*
   * words milestones
   */
  words_250: {
    title: 'words milestone',
    achieved_content: 'Your wrote your first 250 words, the equivalent of a book page.',
    not_achieved_content: 'Write your first 250 words to earn this achievement.',
    color: 'pink',
    share_text: 'I wrote 250 words with WriteNext.'
  },
  words_1000: {
    title: 'words milestone',
    achieved_content: 'Good start! You wrote one thousand words.',
    not_achieved_content: 'Write one thousand words to earn this achievement.',
    color: 'pink',
    share_text: 'I wrote 1000 words with WriteNext.'
  },
  words_5000: {
    title: 'words milestone',
    achieved_content: 'You\'re getting some momentum! You wrote five thousand words.',
    not_achieved_content: 'Write five thousand words to earn this achievement.',
    color: 'pink',
    share_text: 'I wrote 5000 words with WriteNext.'
  },
  words_10000: {
    title: 'words milestone',
    achieved_content: 'Things are getting serious! You wrote ten thousand words.',
    not_achieved_content: 'Write ten thousand words to earn this achievement.',
    color: 'pink',
    share_text: 'I am the writer of 10,000 words with WriteNext.'
  },
  words_20000: {
    title: 'words milestone',
    achieved_content: 'Impressive job! You wrote twenty thousand words.',
    not_achieved_content: 'Write twenty thousand words to earn this achievement.',
    color: 'pink',
    share_text: 'I am the writer of 20,000 words with WriteNext.'
  },
  words_50000: {
    title: 'words milestone',
    achieved_content: 'Don\'t stop! You wrote fifty thousand words.',
    not_achieved_content: 'Write fifty thousand words to earn this achievement.',
    color: 'pink',
    share_text: 'I am the writer of 50,000 words with WriteNext.'
  },
  words_100000: {
    title: 'words milestone',
    achieved_content: 'You are a pro! You wrote one hundred thousand words.',
    not_achieved_content: 'Write one hundred thousand words to earn this achievement.',
    color: 'pink',
    share_text: 'I am the writer of 100,000 words with WriteNext.'
  },
  words_500000: {
    title: 'words milestone',
    achieved_content: 'Keep writing! You wrote five hundred thousand words.',
    not_achieved_content: 'Write five hundred thousand words to earn this achievement.',
    color: 'pink',
    share_text: 'I am the writer of 500,000 words with WriteNext.'
  },
  words_1000000: {
    title: 'words milestone',
    achieved_content: 'WOW! You wrote one million words.',
    not_achieved_content: 'Write one million words to earn this achievement.',
    color: 'pink',
    share_text: 'I am the writer of 1,000,000 words with WriteNext.'
  },
  words_2000000: {
    title: 'words milestone',
    achieved_content: 'You are a true writer! You wrote two million words.',
    not_achieved_content: 'Write two million words to earn this achievement.',
    color: 'pink',
    share_text: 'I am the writer of 2,000,000 words with WriteNext.'
  },
  words_3000000: {
    title: 'words milestone',
    achieved_content: 'We bet you have millions of fans! You wrote three million words.',
    not_achieved_content: 'Write three million words to earn this achievement.',
    color: 'pink',
    share_text: 'I am the writer of 3,000,000 words with WriteNext.'
  },

  /*
   * Streaks
   */
  streak_5: {
    title: 'writing streak',
    achieved_content: 'Good start! You wrote five days in a row.',
    not_achieved_content: 'Write in five consecutive days to earn this achievement.',
    color: 'green',
    share_text: 'I wrote every day in the last 5 days with WriteNext.'
  },
  streak_10: {
    title: 'writing streak',
    achieved_content: 'Just write! You wrote ten days in a row.',
    not_achieved_content: 'Write in ten consecutive days to earn this achievement.',
    color: 'green',
    share_text: 'I wrote every day in the last 10 days with WriteNext.'
  },
  streak_20: {
    title: 'writing streak',
    achieved_content: 'No day without writing! You wrote twenty days in a row.',
    not_achieved_content: 'Write in twenty consecutive days to earn this achievement.',
    color: 'green',
    share_text: 'I wrote every day in the last 20 days with WriteNext.'
  },
  streak_30: {
    title: 'writing streak',
    achieved_content: 'Don\'t stop! You wrote thirty days in a row.',
    not_achieved_content: 'Write in thirty consecutive days to earn this achievement.',
    color: 'green',
    share_text: 'I wrote every day in the last 30 days with WriteNext.'
  },
  streak_max: {
    title: 'writing streak',
    achieved_content: 'Keep writing! Your longest writing streak is #info# days.',
    not_achieved_content: 'Start writing to begin your personal writing streak.',
    color: 'green',
    share_text: 'I wrote #info# days in a row in with WriteNext. That\'s my longest writing streak.'
  },

  /*
   * Writing Days in a Week
   */
  week_3: {
    title: 'Days per Week',
    achieved_content: 'Good job! You wrote in three days this week.',
    not_achieved_content: 'Write three days in one week to earn this achievement.',
    color: 'blue',
    share_text: 'I wrote for 3 days during the last week with WriteNext.'
  },
  week_4: {
    title: 'Days per Week',
    achieved_content: 'Keep up the momentum! This is your fourth writing day this week.',
    not_achieved_content: 'Write four days in one week to earn this achievement.',
    color: 'blue',
    share_text: 'I wrote for 4 days during the last week with WriteNext.'
  },
  week_5: {
    title: 'Days per Week',
    achieved_content: 'You\'re almost there! You wrote in five days this week.',
    not_achieved_content: 'Write five days in one week to earn this achievement.',
    color: 'blue',
    share_text: 'I wrote for 5 days during the last week with WriteNext.'
  },
  week_6: {
    title: 'Days per Week',
    achieved_content: 'Don\'t stop, just one day left! You wrote in six days this week.',
    not_achieved_content: 'Write six days in one week to earn this achievement.',
    color: 'blue',
    share_text: 'I wrote for 6 days during the last week with WriteNext.'
  },
  week_7: {
    title: 'Days per Week',
    achieved_content: 'Congrats! You wrote every day this week.',
    not_achieved_content: 'Write seven days in one week to earn this achievement.',
    color: 'blue',
    share_text: 'I wrote every days during the last week with WriteNext.'
  }
}

export const THRESHOLDS = thresholds;
