import React from "react";
import {useAuth0} from "../react-auth0-spa";
import {Link, NavLink} from "react-router-dom";
import ROUTES from "../utils/routes";
import logo from "../images/writenext-logo.svg";

const NavBar = (props) => {
    const {isAuthenticated, loginWithRedirect, logout} = useAuth0();

    return (
        <nav className="navbar navbar-expand-lg navbar-light">
            <NavLink to={ROUTES.HOME} className="navbar-brand">
                <img src={logo} alt="Writenext" height="30"/>
                {props.isBeta ? <h6 className="ml-1 highlight">BETA</h6> : null}
            </NavLink>
            <button className="navbar-toggler" type="button" data-toggle="collapse"
                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                    aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                <ul className="navbar-nav">
                    <li className="nav-item mr-4">
                        <NavLink to={ROUTES.HOME} exact={true} className="nav-link">Profile</NavLink>
                    </li>
                    <li className="nav-item mr-4">
                        <NavLink to={ROUTES.WRITINGS} exact={true} className="nav-link">My Writings</NavLink>
                    </li>
                    <li className="nav-item mr-4">
                        <NavLink to={ROUTES.SETTINGS} className="nav-link">Account</NavLink>
                    </li>
                    <li className="nav-item mr-4">
                        <a href={ROUTES.CONTACT} className="nav-link">Contact</a>
                    </li>
                    <li className="nav-item mr-4">
                        <Link to="#" className="nav-link" onClick={() => logout()}>Logout</Link>
                    </li>
                    <li className="nav-item">
                        <NavLink to={ROUTES.WRITING_NEW} exact={true} className="btn btn-primary"
                                 role="button">Write</NavLink>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default NavBar;
