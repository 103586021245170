import React, {useEffect, useLayoutEffect, useState} from "react";
import groupArray from "group-array";
import moment from "moment";
import axios from "axios";
import {useAuth0} from "../../react-auth0-spa";
import CalendarHeatmap from 'react-calendar-heatmap';
import ReactTooltip from 'react-tooltip';
import 'react-calendar-heatmap/dist/styles.css';

const ProfileHistory = (props) => {
  const [ wordsByDay, setwordsByDay ] = useState({});
  const [ redrawKey, setredrawKey ] = useState(0);
  const [ selectedDate, setselectedDate ] = useState(null);
  const [ loading, setloading ] = useState(false);
  const { getTokenSilently } = useAuth0();

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useLayoutEffect(() => {
    let onResize = () => {
      setredrawKey(prevKey => prevKey + 1);
    }

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, [])

  const getColor = (count) => {
    const words = [...new Set(Object.values(wordsByDay).map((el) => el.reduce((sum, cur) => sum + cur.words, 0)).sort((a,b) => a - b))]

    const pos = words.indexOf(count)

    if (pos < words.length / 4) {
      return 1
    }
    else if (pos < words.length / 2) {
      return 2
    }
    else if (pos < words.length / 4 * 3) {
      return 3
    }

    return 4
  }

  const processWords = (ary) => {
    let result = ary.map((el) => {
      return {
        edit_date: moment(new Date(el.edit_date)).format('YYYY-MM-DD'),
        words: el.words,
        _id: el._id
      }
    })
    result = groupArray(result, 'edit_date')

    return result
  }

  useEffect(() => {
    setwordsByDay(processWords(props.history))
  }, [props.history])

  const updateSelectedDate = (date) => {
    return true;
  }

  const getCellSize = () => {
    const width = window.innerWidth

    return Math.floor(0.89 * Math.min(width, 1140) / 52);
  }

  const getHistory = () => {
    let results = []
    for (let date in wordsByDay) {
      results.push({
        date: new Date(date),
        count: wordsByDay[date].map(el => el.words).reduce((sum, cur) => sum + cur, 0)
      })
    }

    return results
  }

  const heatmapStartDate = moment().add(-355, "day")
  const heatmapEndDate = moment().add(10, "day")

  return (
      <section id="profile-writings" className="pt-md-5 pb-md-5 pt-2 pb-0">
        <div className="row">
          <div className="col">
            <h2 className="gray section-title">Writing History</h2>
          </div>
        </div>

        <div className="row pt-3">
        <div className="col">
          <CalendarHeatmap
              startDate={heatmapStartDate}
              endDate={heatmapEndDate}
              values={getHistory()}
              classForValue={(value) => {
                if (!value) {
                  return 'color-empty';
                }
                return `color-scale-${getColor(value.count)}`;
              }}
              tooltipDataAttrs={(value) => {
                return value.count ? {
                  'data-tip': `<strong>${value.count} words</strong> wrriten on ${moment(value.date).format('MMM DD, YYYY')}`
                } : {
                  'data-tip': `<strong>No words</strong> written.`
                }
              }}
              showWeekdayLabels={true}
              weekdayLabels={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
            gutterSize={2}
            />

            <ReactTooltip html={true}/>
        </div>
      </div>

      <div id="legend" className="row mt-2">
        <div className="col text-right">
          <span className="mr-2">Less</span>
          <span className="color-empty"></span>
          <span className="color-scale-1"></span>
          <span className="color-scale-2"></span>
          <span className="color-scale-3"></span>
          <span className="color-scale-4"></span>
          <span className="ml-2">More</span>
        </div>
      </div>
    </section>
  )
}

export default ProfileHistory;
