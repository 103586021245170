import React, {Fragment, useEffect, useState} from "react";
import {useAuth0} from "../react-auth0-spa";
import axios from "axios";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import {ACHV_TITLES, THRESHOLDS} from "../utils/achievements";
import AchievementBadge from "../components/AchievementBadge";
import Loader from "../components/Loader";

const images = {}
const images_disabled = {}

for (let badge in THRESHOLDS) {
  THRESHOLDS[badge].forEach((val) => {
    try {
      images[`${badge}_${val}`] = require(`../images/achievements/${badge}/${val}-01.svg`)
      images_disabled[`${badge}_${val}`] = require(`../images/achievements/${badge}/${val}-02.svg`)
    }
    catch (err) {

    }
  })
}

const Achievements = (props) => {
  const [fetching, setfetching] = useState(true);
  const [achievements, setachievements] = useState({});
  const {loading, user, getTokenSilently} = useAuth0();

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  // Make request to server store / load user data.
  useEffect(() => {
    getTokenSilently()
    .then(token =>
      axios.all([
        axios({
          url: `${process.env.REACT_APP_API_URL}/achievements`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`
          },
          cancelToken: source.token
        })
      ])
    )
    .then(axios.spread((...responses) => {
      setachievements([...responses[0].data].reduce((map, el) => {
        if (!map[el.name]) {
          map[el.name] = el
        }
        
        return map
      }, {}))

      setfetching(false)
    }))
    .catch(error => {
      console.log(error)
    })

    return () => {
      source.cancel('Hook cleanup.');
    }
  }, [])

  if (loading || !user || fetching) {
    return <Loader />
  }

  const listAchv = (type) => {
    let achvs = []

    for (let val of THRESHOLDS[type]) {
      let name = `${type}_${val}`

      if (achievements[name]) {
        achvs.push(<AchievementBadge src={images[name]} key={name} name={name} info={achievements[name].info} createdAt={achievements[name].createdAt}/>)
      }
      else {
        achvs.push(<AchievementBadge src={images_disabled[name]} key={name} name={name} disabled={true}/>)
      }
    }

    return (
      <section className="pt-3 pb-5" key={type}>
        <div className="row">
          <div className="col">
            <h2 className="gray section-title">{ACHV_TITLES[type]}</h2>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col achvs">
            {achvs}
          </div>
        </div>
      </section>)
  }

  const listAchvs = () => {
    const result = []
    for (let badge in THRESHOLDS) {
      result.push(listAchv(badge))
    }

    return result
  }

  const latestAchvs = () => {
    let achvs = []

    let i = 0;
    for (let name in achievements) {
      if (images[name]) {
        achvs.push(<AchievementBadge src={images[name]} key={name} name={name} info={achievements[name].info} createdAt={achievements[name].createdAt}/>)
      }

      if (++i == 8) {
        break;
      }
    }

    return achvs
  }

  return (
    <Fragment>
      <div className="container-xl">
        <NavBar isBeta={props.isBeta}></NavBar>
        <div className="achievements-page">

          <section className="pb-5 pt-5">
            <div className="row">
              <div className="col col-md-6">
                <h2 className="section-title">My Achievements</h2>
                <p id="achievements-text">It’s hard to keep track of all your milestones, so let us do it for you so you
                  can just write.</p>
              </div>
            </div>
          </section>

          <section className="pt-3 pb-5">
            <div className="row">
              <div className="col">
                <h2 className="gray section-title">Latest Achievements</h2>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col achvs">
                {latestAchvs()}
              </div>
            </div>
          </section>

          {listAchvs()}
        </div>
      </div>

      <Footer></Footer>
    </Fragment>
  );
};

export default Achievements;
