import React, {useEffect, useState} from "react";
import {useAuth0} from "../../react-auth0-spa";
import axios from "axios";
import {Link} from "react-router-dom";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import Loader from "../../components/Loader";
import moment from "moment";
import {saveSync} from 'save-file'
import jsPDF from "jspdf";

const RESULTS_PER_PAGE = 7

const topSpace = 25
const bottomSpace = 25
const leftSpace = 25
const rightSpace = 25

const EditWriting = (props) => {
  const [ fetching, setfetching ] = useState(true);
  const [fetchingMore, setfetchingMore] = useState(false);
  const {loading, getTokenSilently, loginWithRedirect} = useAuth0();
  const [writings, setwritings] = useState([]);
  const [ skip, setskip ] = useState(0);
  const [ allLoaded, setallLoaded ] = useState(false);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    fetchWritings()

    return () => {
      source.cancel('Hook cleanup.');
    }
  }, []);

  const fetchWritings = () => {
    getTokenSilently()
    .then(token =>
      axios({
        url: `${process.env.REACT_APP_API_URL}/writings/`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          limit: RESULTS_PER_PAGE,
          skip: skip
        },
        cancelToken: source.token
      })
    )
    .then(response => {
      setskip(skip => skip + RESULTS_PER_PAGE)
      setwritings([...writings, ...response.data])
      setfetching(false)
      setfetchingMore(false)

      if (response.data.length == 0 ) {
        setallLoaded(true)
      }
    })
    .catch(error => {
      console.log(error);
      loginWithRedirect({redirect_uri: window.location.href})
    })
  }

  let deleteWriting = (e, writing) => {
    e.preventDefault()

    if (window.confirm('Are you sure you want to delete this writing?')) {
      getTokenSilently()
      .then(token =>
        axios({
          url: `${process.env.REACT_APP_API_URL}/writings/${writing._id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`
          },
          cancelToken: source.token
        })
      )
      .then(response => {
        setwritings(writings.filter((e) => (e != writing)))
      })
      .catch(error => {
        console.log(error);
      })
    }
  }

  const timeAgo = (date) => {
    const diff_days = moment().diff(date, "days")
    const diff_weeks = moment().diff(date, "weeks")
    const diff_months = moment().diff(date, "months")

    if (diff_days == 0) {
      return 'today'
    }

    if (diff_months == 1) {
      return `one month ago`
    }
    else if (diff_months > 1) {
      return `${diff_months} months ago`
    }

    if (diff_weeks == 1) {
      return `one week ago`
    }
    else if (diff_weeks > 1) {
      return `${diff_weeks} months ago`
    }

    if (diff_days > 1) {
      return `${diff_days} days ago`
    }

    return `one day ago`
  }

  const exportWritingTxt = (e, writing) => {
    e.preventDefault();

    const blob = new Blob([writing.text], { type: "text/plain;charset=utf-8" })

    saveSync(blob, `${writing.title || 'Untitled'}.txt`)
  }

  const printText = (doc, text, y, center) => {
    const content = doc.splitTextToSize(text, doc.internal.pageSize.width - leftSpace - rightSpace);

    for (var i = 0; i < content.length; i++) {
      if (y > doc.internal.pageSize.height - bottomSpace) {
          y = topSpace;
          doc.addPage();
      }

      if (center) {
        var textWidth = doc.getStringUnitWidth(content[i]) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        var textOffset = (doc.internal.pageSize.width - textWidth) / 2;

        doc.text(textOffset, y, content[i]);
      }
      else {
        doc.text(leftSpace, y, content[i]);
      }

      y = y + 7;
    }

    return y
  }

  const generatePdf = (writing, options = {}) => {
    const doc = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: 'letter'
    })

    doc.setFont("helvetica");
    doc.setFontSize(16);

    const title = writing.title || 'Untitled'

    let y = printText(doc, title, topSpace, true)

    doc.setFontSize(12);
    printText(doc, writing.text, y + 10, false)

    if (options.print) {
      doc.autoPrint();

      doc.output('dataurlnewwindow');
    }
    else {
      doc.save(`${writing.title || 'Untitled'}.pdf`)
    }
  }

  const exportWritingPdf = (e, writing) => {
    e.preventDefault();

    generatePdf(writing)
  }

  const print = (e, writing) => {
    e.preventDefault();

    generatePdf(writing, {print: true})
  }

  const renderWritings = () => {
    const sections = []

    for (let writing of writings) {
      sections.push(
        <section key={writing._id} className="pb-5">
          <h2 className="gray section-title">{writing.title || 'Untitled Writing'}</h2>
          <p className="writing-content mb-1">{writing.text}</p>
          <p className="writing-info mb-1">created {timeAgo(writing.createdAt)} &bull; {writing.words} words</p>
          <p className="writing-actions mb-1">
            <Link className="highlight" to={`/writings/${writing._id}`}>Edit</Link>
            <a className="highlight" href="#" onClick={(e) => exportWritingTxt(e, writing)}>Export Text</a>
            <a className="highlight" href="#" onClick={(e) => exportWritingPdf(e, writing)}>Export PDF</a>
            <a className="highlight" href="#" onClick={(e) => print(e, writing)}>Print</a>
            <a className="highlight" href="#" onClick={(e) => deleteWriting(e, writing)}>Delete</a>
          </p>
        </section>
      )
    }

    return sections
  }

  const loadMore = () => {
    setfetchingMore(true)
    fetchWritings()
  }

  const renderLoadMore = () => {
    if (allLoaded) {
      return ''
    }

    return (
      <div id="load-more" className="mt-3">
        <button onClick={loadMore}>Load More Writings</button>
      </div>
    )
  }

  if (loading || fetching) {
    return <Loader />;
  }

  return (
    <>
      <div id="writings-page" className="container-xl">
        <NavBar isBeta={props.isBeta}></NavBar>
        <section className="pt-5 pb-5">
          <h2 className="section-title">My Writings</h2>
        </section>

        {renderWritings()}

        {renderLoadMore()}
      </div>

      <Footer></Footer>
    </>
  )
}

export default EditWriting;
