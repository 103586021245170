const getScore = (info) => {
    let scoreForShowingUp = 0;
    let scoreForHittingTargets = 0;

    if (info.total_words_today > 0) {
        scoreForShowingUp = 25;
    }

    scoreForHittingTargets = Math.floor(Math.min(Math.max(info.total_words_today / info.daily_words, info.total_minutes_today / info.daily_minutes), 1) * 75);

    if (isNaN(scoreForShowingUp + scoreForHittingTargets)) {
        return 0;
    }

    return scoreForShowingUp + scoreForHittingTargets;
}

export {getScore}