import React, {useState, useEffect} from "react";
import { ACHIEVEMENT_TEXTS } from "../utils/achievements";
import moment from "moment";
import { Link } from "react-router-dom";
import ROUTES from "../utils/routes";
import { FacebookShareButton, TwitterShareButton } from "react-share";

const AchievementModal = (props) => {
  const [dismissed, setdismissed] = useState(false)

  useEffect(() => {
    // On props change, show modal.
    setdismissed(false)
  }, [props.show])

  // No info or we've dismissed the modal.
  if (!props.show || dismissed) {
    document.body.style.overflow = ''

    return ''
  }
  else {
    document.body.style.overflow = 'hidden'
  }

  const dismissCallback = () => {
    document.body.style.overflow = ''
    setdismissed(true)

    if (props.dismissAction) {
      props.dismissAction.call()
    }
  }


  return (
    <div className="modal" id={props.id}>
      <div className="overlay"></div>
      <div className="modal-content text-center">
        { props.children }

        <button className="modal-dismiss" onClick={dismissCallback}>&times;</button>
      </div>
    </div>
  )
}

export default AchievementModal;
