import React, {useEffect, useState} from "react";
import {useAuth0} from "../react-auth0-spa";
import axios from "axios";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import Loader from "../components/Loader";

import ProfileMain from "../components/profile/ProfileMain"
import ProfileStats from "../components/profile/ProfileStats"
import ProfileAchievements from "../components/profile/ProfileAchievements";
import ProfileHistory from "../components/profile/ProfileHistory";

import moment from "moment";

const Home = (props) => {
    const [fetching, setfetching] = useState(true);
    const [info, setinfo] = useState({});
    const {loading, user, getTokenSilently, loginWithRedirect} = useAuth0();
    const [history, setHistory] = useState([]);
    const [achievements, setachievements] = useState([]);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    // Make request to server store / load user data.
    useEffect(() => {
        if (props.userSplitTestingHexDigit !== '') {
            getTokenSilently()
                .then(token =>
                    axios.all([
                        axios({
                            url: `${process.env.REACT_APP_API_URL}/infos`,
                            method: "GET",
                            headers: {
                                Authorization: `Bearer ${token}`,
                                Now: moment(new Date()).format('YYYY-MM-DD'),
                                Visit_Id: user.visit_id || ""
                            },
                            cancelToken: source.token
                        }),
                        axios({
                            url: `${process.env.REACT_APP_API_URL}/history`,
                            method: "GET",
                            headers: {
                                Authorization: `Bearer ${token}`
                            },
                            cancelToken: source.token
                        }),
                        axios({
                            url: `${process.env.REACT_APP_API_URL}/achievements`,
                            method: "GET",
                            headers: {
                                Authorization: `Bearer ${token}`
                            },
                            cancelToken: source.token
                        })
                    ])
                )
                .then(axios.spread((...responses) => {
                    setinfo(responses[0].data)
                    setHistory([...responses[1].data])
                    setachievements([...responses[2].data])

                    setfetching(false)
                }))
                .catch(error => {
                    console.log(error);
                    loginWithRedirect({redirect_uri: window.location.href});
                })
        }
        return () => {
            source.cancel('Hook cleanup.');
        }
    }, [props.userSplitTestingHexDigit])

    if (loading || !user || fetching) {
        return <Loader/>;
    }

    return (
        <>
            <div className="container-xl">
                <NavBar isBeta={props.isBeta}></NavBar>
                <div className="profile-page">

                    <ProfileMain user={user} info={info}></ProfileMain>
                    <ProfileStats info={info}></ProfileStats>
                    <ProfileAchievements achievements={achievements}></ProfileAchievements>
                    <ProfileHistory history={history}></ProfileHistory>
                </div>
            </div>

            <Footer></Footer>
        </>
    );
};

export default Home;
