import React from "react";
import {AppRouter} from "./AppRouter";
import {useAuth0} from "./react-auth0-spa";
import Loader from "./components/Loader";

import './App.scss';
// import ReactGA from "react-ga";

const App = () => {
  const {loading, user} = useAuth0();

//   const trackingId = "G-8WJJ2F5TPP"; // Replace with your Google Analytics tracking ID
//   ReactGA.initialize(trackingId);
//   if (user) {
//     ReactGA.set({
//       userId: user.sub,
//     })
//   }
// // Initialize google analytics page view tracking
//   history.listen(location => {
//     ReactGA.set({page: location.pathname}); // Update the user's current page
//     ReactGA.pageview(location.pathname); // Record a pageview for the given page
//   });


  if (loading) {
    return <Loader/>;
  }

  return (
      <AppRouter/>
  );
};

export default App;
