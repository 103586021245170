import React, { useState, useEffect, useRef } from "react";
import { THRESHOLDS } from "../../utils/achievements";
import AchievementBadge from "../AchievementBadge";
import { Link } from "react-router-dom";
import ROUTES from "../../utils/routes";

const images = {}
const images_disabled = {}

for (let badge in THRESHOLDS) {
  THRESHOLDS[badge].forEach((val) => {
    try {
      images[`${badge}_${val}`] = require(`../../images/achievements/${badge}/${val}-01.svg`)
      images_disabled[`${badge}_${val}`] = require(`../../images/achievements/${badge}/${val}-02.svg`)
    }
    catch (err) {

    }
  })
}

const ProfileAchievements = (props) => {
  const randomDisabled = useRef([])

  const currentAchievements = () => {
    let achvs = []
    let i = 0

    let achvs_names = {}

    for (let achv of props.achievements) {
      if (i === 8) {
        break;
      }

      delete images_disabled[achv.name];

      if (images[achv.name] && !achvs_names[achv.name]) {
        achvs.push(<AchievementBadge key={achv.name + i} name={achv.name} info={achv.info} src={images[achv.name]} createdAt={achv.createdAt}></AchievementBadge>)

        achvs_names[achv.name] = true

        i++;
      }
    }

    // Build a random disabled list. This happens only the first time.
    if (randomDisabled.current.length == 0) {
      let items = Object.keys(images_disabled)
      while (i++ < 8) {
        let k = Math.floor(Math.random() * items.length)
        randomDisabled.current.push(items[k])
        items.splice(k, 1)
      }
    }

    // Push disabled items.
    for (let key of randomDisabled.current) {
      achvs.push(<AchievementBadge key={key} name={key} src={images_disabled[key]} disabled={true}></AchievementBadge>)
    }

    return achvs
  }

  return (
    <>
      <section id="profile-achievements" className="pt-md-5 pb-md-4 pt-4 pb-2">
        <div className="row">
          <div className="col">
            <h2 className="gray section-title">Writing Achievements</h2>
            <p className="mb-0">
              <Link to={ROUTES.ACHIEVEMENTS} className="highlight">View All</Link>
            </p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col achvs">
            {currentAchievements()}
          </div>
        </div>
      </section>
    </>
  )
}

export default ProfileAchievements;
