import React from "react";

const Circle = (props) => {

    // return (
    //     <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
    //          viewBox="0 0 36 36" className="circular-chart mr-2 mr-md-4" height={props.height}>
    //         <defs>
    //             <circle id="circle" cx="32" cy="18" r="16" transform="rotate(-90 25 25)"></circle>
    //         </defs>
    //         <g className="circleFill" strokeDasharray="100, 100" fill="none" stroke={props.color} strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
    //             <use xlinkHref="#circle"/>
    //         </g>
    //         <g className="circle anim" strokeDasharray={`${Math.floor(props.counter * 100 / props.total)}, 100`} fill="none" stroke={props.color} strokeWidth="5" strokeLinecap="butt"
    //            strokeLinejoin="round">
    //             <use  xlinkHref="#circle"/>
    //         </g>
    //         <text x="50%" y="15" dominantBaseline="middle" textAnchor="middle" className="tm">{props.counter}</text>
    //         <text x="50%" y="22" dominantBaseline="middle" textAnchor="middle" className="ts">{props.text}</text>
    //     </svg>
    // );

    return (
        <svg viewBox="0 0 36 36" className="circular-chart mr-2 mr-md-4" height={props.height}>

            <path className="circleFill"
                  strokeDasharray="100, 100"
                  style={{
                      stroke: props.color
                  }}
                  d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
            />

            <path className="circle"
                  strokeDasharray={`${Math.floor(props.counter * 100 / props.total)}, 100`}
                  style={{
                      stroke: props.color,
                      animation: "progress 1s ease-out forwards"
                  }}
                  d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
            />

            <text x="50%" y="15" dominantBaseline="middle" textAnchor="middle" className="tm">{props.counter}</text>
            <text x="50%" y="22" dominantBaseline="middle" textAnchor="middle" className="ts">{props.text}</text>
        </svg>
    );
}

export default Circle;
