import React, {useEffect, useRef, useState} from "react";
import {useAuth0} from "../../react-auth0-spa";
import axios from "axios";
import wordcount from "wordcount";
import ROUTES from "../../utils/routes";
import history from "../../utils/history";
import Loader from "../../components/Loader";
import DoneWritingModal from "./DoneWritingModal";
import moment from "moment";
import logo from "../../images/writenext-logo.svg";
import Circle from "../../components/Circle";
import {getScore} from "../../utils/score";
import {getSplitTestingCssClass, getSplitTestingUserGroupWithDate} from "../../utils/splitTesting";

const NEW_NOTE = "new";
const EMPTY_TEXT = "";

const SAVE_INTERVAL = 3 * 1000; // 3 seconds
const BREAK_INTERVAL = 180 * 1000; // 3 minutes
const IDLE_INTERVAL = 10 * 1000; // 10 seconds

const EditWriting = (props) => {
    const [writingText, setwritingText] = useState(EMPTY_TEXT);
    const [writingTitle, setwritingTitle] = useState(EMPTY_TEXT);
    const [wordNo, setwordNo] = useState(0);
    const [writingTime, setWritingTime] = useState(0);
    const
        [writingId, setwritingId] = useState(props.match.params.writingId || NEW_NOTE);
    const [fetching, setfetching] = useState(true);
    const [info, setinfo] = useState({});
    const {loading, user, getTokenSilently, loginWithRedirect} = useAuth0();
    const [display, setdisplay] = useState('');
    const [writingDone, setwritingDone] = useState(false);
    const [writingStatsUserGroup, setWritingStatsUserGroup] = useState(0);
    const [writingStatsCssClass, setWritingStatsCssClass] = useState(0);

    // Refs.
    const allowFetch = useRef(true);
    const saved = useRef(true)
    const willSaveTimeout = useRef(null)
    const breakTimeout = useRef(null)
    const startTime = useRef(null)
    const breaks = useRef(0);
    const idleTime = useRef(0);
    const isIdle = useRef(false);
    const idleTimeout = useRef(null);
    const writingStats = useRef({
        writingTime: 0,
        idleTime: 0,
        breaks: 0,
        words: 0,
        initialWords: 0
    })

    const writingStatsFeatureId = 1;

    // Mounting.
    useEffect(() => {
        setWritingStatsUserGroup(getSplitTestingUserGroupWithDate(props.userSplitTestingHexDigit, 2));
        setWritingStatsCssClass(getSplitTestingCssClass(writingStatsFeatureId, props.isBeta ? "f" : props.userSplitTestingHexDigit, 2));
    }, [props.userSplitTestingHexDigit, props.isBeta])

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    // Changing fullscreen.
    const fullscreenChangeCallback = () => {
        let fullscreenEl = document.fullscreenElement || /* Standard syntax */
            document.webkitFullscreenElement || /* Chrome, Safari and Opera syntax */
            document.mozFullScreenElement ||/* Firefox syntax */
            document.msFullscreenElement /* IE/Edge syntax */

        if (fullscreenEl) {
            setdisplay('none');
        } else {
            setdisplay('');
        }
    }

    // Leaving page.
    const beforeUnloadCallback = (e) => {
        if (!saved.current) {
            e = e || window.event;

            // For IE and Firefox prior to version 4
            if (e) {
                e.returnValue = 'Sure?';
            }

            // For Safari
            return 'Sure?';
        }
    }

    // Mounting.
    useEffect(() => {
        document.addEventListener("fullscreenchange", fullscreenChangeCallback);
        document.addEventListener("webkitfullscreenchange", fullscreenChangeCallback);
        window.addEventListener("beforeunload", beforeUnloadCallback);

        return () => {
            source.cancel('Hook cleanup.');
            document.removeEventListener("fullscreenchange", fullscreenChangeCallback);
            document.removeEventListener("webkitfullscreenchange", fullscreenChangeCallback);
            window.removeEventListener("beforeunload", beforeUnloadCallback);
            clearTimeout(willSaveTimeout.current)
            clearTimeout(breakTimeout.curent)
        }
    }, [])

    // Change props.
    useEffect(() => {
        // Set writing id.
        setwritingId(props.match.params.writingId || NEW_NOTE);
    }, [props.match.params.writingId]);

    // Observe changes for writing id.
    useEffect(() => {
        if (allowFetch.current) {
            fetchWriting()
        } else {
            allowFetch.current = false
        }
    }, [writingId]);

    // Text changed.
    useEffect(() => {
        setwordNo(wordcount(writingText));
        // Current text was not saved.
        if (!saved.current) {
            // Clear any timeouts.
            clearTimeout(willSaveTimeout.current)

            // Set new timeout.
            willSaveTimeout.current = setTimeout(() => {
                // We clear timeout first.
                willSaveTimeout.current = null
                saveWriting()
            }, SAVE_INTERVAL)
        }
    }, [writingText, writingTitle]);

    // Fetch writing.
    const fetchWriting = () => {
        // Writing isn't new.
        if (writingId !== NEW_NOTE) {
            getTokenSilently()
                .then(token =>
                    axios({
                        url: `${process.env.REACT_APP_API_URL}/writings/${writingId}`,
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${token}`
                        },
                        cancelToken: source.token
                    })
                )
                .then(response => {
                    setwritingText(response.data.text)
                    setwritingTitle(response.data.title)
                    writingStats.current.initialWords = response.data.words
                    setfetching(false)
                })
                .catch(error => {
                    console.log(error);
                })
        }

        // New Writing => create.
        else {
            getTokenSilently().then(() => {
                // ReactGA.event({
                //   category: "Writing",
                //   action: "User started new writing",
                // });
                setwritingText(EMPTY_TEXT)
                setwritingTitle(EMPTY_TEXT)
                setfetching(false)
            })
                .catch(error => {
                    console.log(error);
                    loginWithRedirect({redirect_uri: window.location.href})
                })
        }
    }


    const refreshStats = () => {
        getTokenSilently()
            .then(token =>
                axios({
                    url: `${process.env.REACT_APP_API_URL}/infos`,
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Now: moment(new Date()).format('YYYY-MM-DD'),
                        Visit_Id: user.visit_id || ""
                    },
                    cancelToken: source.token
                })
                    .then(response => {
                        setinfo(response.data)
                    }))
            .catch(error => {
                console.log(error);
                loginWithRedirect({redirect_uri: window.location.href});
            })

    }

    useEffect(() => {

        refreshStats();
        return () => {
            source.cancel('Hook cleanup.');
        }
    }, [])

    // Save writing was successfull.
    const writingSaveSuccess = (data, now_time, current_breaks) => {
        // If note was new, update props.
        if (writingId === NEW_NOTE) {
            allowFetch.current = false
            setwritingId(data.id);
            props.history.replace(`/writings/${data.id}`);
        }

        // refreshStats();

        setStartTime(now_time)
        breaks.current = breaks.current - current_breaks

        // If nothing changed, then mark as saved.
        if (!willSaveTimeout.current) {
            saved.current = true
        }
    }

    // Save writing error.
    const writingSaveError = () => {
        console.warn('Saving error.');
    }

    // Save writing callback.
    const saveWriting = async (callback) => {
        const token = await getTokenSilently();
        Array.prototype.forEach.call(document.getElementsByClassName("circle"), el => {
            el.style.animation = "";
            el.style.transition = "1s";
        });
        const now_time = new Date()
        const current_breaks = breaks.current
        let current_idle = idleTime.current
        if (current_idle && current_idle > BREAK_INTERVAL) {
            current_idle = 0
        } else if (current_idle > IDLE_INTERVAL) {
            current_idle = current_idle - IDLE_INTERVAL
        } else {
            current_idle = 0
        }
        idleTime.current = 0
        const this_wt = startTime.current ? (now_time - startTime.current) / 1000 : 0
        const this_it = current_idle / 1000
        const this_br = current_breaks
        const this_wr = wordcount(writingText)
        setWritingTime(writingTime + this_wt);
        const response = await axios({
            url: `${process.env.REACT_APP_API_URL}/writings/`,
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                Now: moment(new Date()).format('YYYY-MM-DD')
            },
            data: {
                id: writingId,
                text: writingText,
                title: writingTitle,
                words: this_wr,
                writing_time: this_wt,
                idle_time: this_it,
                distractions: this_br,
                edit_date: moment(new Date()).format('YYYY-MM-DD')
            },
            cancelToken: source.token
        });
        if (response.data.saved === true) {
            // Update stats.
            writingStats.current = {
                writingTime: writingStats.current.writingTime + this_wt,
                idleTime: writingStats.current.idleTime + this_it,
                breaks: writingStats.current.breaks + this_br,
                words: this_wr - writingStats.current.initialWords,
                initialWords: writingStats.current.initialWords
            }

            writingSaveSuccess(response.data, now_time, current_breaks);

            if (callback) {
                callback()
            }
        } else {
            writingSaveError(null);
        }
    };

    // Text was changed.
    const handleWritingTextChange = (event) => {
        // Update saving state.
        saved.current = false

        // Update new text.
        setwritingText(event.target.value);
    }

    const handleWritingTitleChange = (event) => {
        saved.current = false

        setwritingTitle(event.target.value)
    }

    const setStartTime = (time) => {
        if (isIdle && startTime.current) {
            idleTime.current = new Date() - startTime.current
            isIdle.current = false
        }

        // Set current start time.
        startTime.current = time

        // Break time.
        clearTimeout(breakTimeout.current)
        breakTimeout.current = setTimeout(() => {

            // Don't count the break time.
            if (saved.current) {
                breaks.current = breaks.current + 1
                isIdle.current = false
                startTime.current = null
            }
        }, BREAK_INTERVAL)

        // Clear current idle timeout.
        clearTimeout(idleTimeout.current)
        idleTimeout.current = setTimeout(() => {
            isIdle.current = true
        }, IDLE_INTERVAL)
    }

    // Key down is preseed => start counting time.
    const handleKeyDown = () => {
        setStartTime(startTime.current || new Date())
    }

    // Go into fullscreen.
    const toggleFullscreen = () => {
        let fullscreenEl = document.fullscreenElement || /* Standard syntax */
            document.webkitFullscreenElement || /* Chrome, Safari and Opera syntax */
            document.mozFullScreenElement ||/* Firefox syntax */
            document.msFullscreenElement /* IE/Edge syntax */

        if (!fullscreenEl) {
            let el = document.documentElement
            let rfs = el.requestFullscreen
                || el.webkitRequestFullscreen
                || el.mozRequestFullScreen
                || el.msRequestFullscreen;

            rfs.call(el)
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }
    }

    const exitFullScreen = () => {
        let fullscreenEl = document.fullscreenElement || /* Standard syntax */
            document.webkitFullscreenElement || /* Chrome, Safari and Opera syntax */
            document.mozFullScreenElement ||/* Firefox syntax */
            document.msFullscreenElement /* IE/Edge syntax */

        if (fullscreenEl) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }

        }
    }

    let exitFullScreenButton;
    if (props.isBeta || writingStatsUserGroup === 2) {
        exitFullScreenButton =
            <button id="exit-no-distractions" className="btn" onClick={toggleFullscreen}>Exit Full
                Screen</button>
    } else {
        exitFullScreenButton = <button id="exit-fullscreen" onClick={toggleFullscreen}>&times;</button>
    }
    // Render distraction free.
    const renderDistractionFree = () => {
        if (document.fullscreenEnabled || document.mozFullscreenEnabled || document.webkitFullscreenEnabled) {
            return <>
                <button id="no-distractions" className="btn" onClick={toggleFullscreen}>Full Screen</button>
                {exitFullScreenButton}
            </>
        }

        return '';
    }

    // Save and exit.
    const saveAndExit = () => {
        exitFullScreen();
        // It was not saved.
        if (!saved.current) {
            clearTimeout(willSaveTimeout.current)
            willSaveTimeout.current = null

            saveWriting(() => {
                setwritingDone(true)
                updateSendGridContact();
            })
        }

        // It was saved.
        else {
            if (writingStats.current.words > 0) {
                setwritingDone(true)
                updateSendGridContact();
            } else {
                history.push(ROUTES.HOME)
            }
        }
    }

    const updateSendGridContact = async () => {
        const token = await getTokenSilently();
        await axios({
            url: `${process.env.REACT_APP_API_URL}/sendgridContact/`,
            method: "PATCH",
            headers: {
                Authorization: `Bearer ${token}`,
                Now: moment(new Date()).format('YYYY-MM-DD')
                },
            data: {},
            cancelToken: source.token
        });
    }

    if (loading || !user || fetching || isNaN(writingStatsUserGroup) || writingStatsCssClass === 0 || !info.daily_words) {
        return <Loader/>;
    }

    let sideLogo;
    if (props.isBeta || writingStatsUserGroup === 2) {
        sideLogo = <img id="writenextfadedlogo" src={logo} alt="Writenext" height="30"/>
    } else {
        sideLogo = <svg id="write" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 297 913">
            <path
                d="M140.28,762.93q19.28,5.75,38.95,11.68T217,786.71q18,6.15,34,11.89t28.29,10.66a30.23,30.23,0,0,1,3.28,7.38,40,40,0,0,1,1.23,11.07,31.77,31.77,0,0,1-2.26,12.09,13.09,13.09,0,0,1-7.17,7.59q-10.25,4.92-32,11.68T194.6,873q-26,7.18-52.48,13.53T96,897a33.84,33.84,0,0,1-5.33-7.18,20.74,20.74,0,0,1-2.46-10.45q0-7.79,4.3-11.48t13.74-5.74q17.22-3.69,39.15-8.61t42.85-9.84q20.91-4.92,38.13-9.23l25.42-6.35v-1.64q-7.38-2.46-22.14-7.18t-33.83-10.86q-19.07-6.15-41.2-13.53l-44.28-14.76a35.81,35.81,0,0,1-4.1-17.63q0-15.16,10.66-18.86,21.32-7.38,42.43-14.15T198.5,717q18-5.73,32.18-9.84t21.53-6.56v-2.05q-36.9-9.42-78.31-20.5t-82-18a25.22,25.22,0,0,1-3.69-13.94q0-7,3.28-11.07t10.25-4.1q4.52,0,17.42,2.66t29.93,7.18q17,4.51,36.49,10t37.72,11.28q18.25,5.74,33.21,10.86a235.23,235.23,0,0,1,22.76,8.82,21.35,21.35,0,0,1,3.07,6.76,37.32,37.32,0,0,1,1.44,10.87,38.49,38.49,0,0,1-1.85,12.91q-1.85,5.15-5.94,6.77-11.07,4.52-26.24,9.63t-33,10.87q-17.84,5.75-37.51,11.68T140.28,762.93Z"/>
            <path
                d="M280.5,550.55a51.71,51.71,0,0,1,1.84,6.15,37.37,37.37,0,0,1,1,9q0,17.64-14.76,17.63h-139q-9.44,0-15-3.48t-10.86-11.69q-7-10.65-12.1-28.9t-5.12-41.21q0-32.79,16.4-32.8a22.61,22.61,0,0,1,7.58,1.23,19.21,19.21,0,0,1,6,3.28q-.82,4.11-1.64,10.66a105.28,105.28,0,0,0-.82,13.12q0,18.87,3.89,33.21a104,104,0,0,0,9.23,23.78Z"/>
            <path
                d="M32,429.61a19.8,19.8,0,0,1-14.56-5.95q-5.94-5.93-5.94-15.37t5.94-15.17A20.17,20.17,0,0,1,32,387.38a20.13,20.13,0,0,1,14.55,5.74q6,5.75,5.95,15.17t-5.95,15.37A19.76,19.76,0,0,1,32,429.61ZM280.5,391.89a50.56,50.56,0,0,1,1.84,6.15,37.31,37.31,0,0,1,1,9q0,17.63-14.76,17.63H92.72a50,50,0,0,1-1.85-6.36,39.89,39.89,0,0,1-1-9.22q0-17.22,15.17-17.22Z"/>
            <path
                d="M286.65,263.15q0,28.71-12.71,46.54t-42.23,17.83H37a49.25,49.25,0,0,1-1.85-6.35,40,40,0,0,1-1-9.23q0-17.22,14.76-17.22H96V227.48a31.33,31.33,0,0,1,5.53-2.46,22.71,22.71,0,0,1,7.59-1.23q13.93,0,13.94,12.3v58.63h106.6q16.4,0,23.16-9.22t6.77-26a57.3,57.3,0,0,0-2.46-15.58,65.12,65.12,0,0,0-5.74-14.35,27.66,27.66,0,0,1,6.15-4.51,17.51,17.51,0,0,1,8.61-2.05q9,0,14.76,10.66T286.65,263.15Z"/>
            <path
                d="M205.88,158.19q53.72-11.07,53.71-70.52a81.1,81.1,0,0,0-5.74-31.16q-5.74-13.93-12.3-22.14,5.74-9.84,15.58-9.84,5.75,0,11.07,5.13a46.05,46.05,0,0,1,9.43,13.94,95.36,95.36,0,0,1,6.56,20.5,122.3,122.3,0,0,1,2.46,25.21q0,47.57-25.83,75.24t-75.44,27.67q-23.37,0-41.62-6.76a89.29,89.29,0,0,1-30.95-18.86,81.25,81.25,0,0,1-19.48-28.91,96.81,96.81,0,0,1-6.76-36.49,91.89,91.89,0,0,1,6.15-34,78.12,78.12,0,0,1,17.42-26.85,81,81,0,0,1,26.65-17.63,87.68,87.68,0,0,1,33.83-6.36q9,0,12.91,4.1t4.72,11.48Zm-92.66-56.58q0,25,17.42,41.41t49.82,17.22L164.88,47.49q-22.55,2.05-37.11,15.79T113.22,101.61Z"/>
        </svg>
    }

    let wheels = "";
    if (props.isBeta || writingStatsUserGroup === 2) {
        wheels = <div className="wheels">
            <Circle height="100" color="#d43c67"
                    counter={(isNaN(info.total_words_today) ? 0 : info.total_words_today) + wordNo} text="words"
                    total={info.daily_words || 100}/>
            <Circle height="100" color="#174bc0"
                    counter={(isNaN(Math.round((info.total_minutes_today) * 10) / 10) ? 0 : Math.round((info.total_minutes_today + (writingTime / 60)) * 10) / 10)}
                    text="minutes"
                    total={info.daily_minutes || 100}/>
            <Circle height="100" color="#00afb5" counter={getScore({...info, total_words_today: (isNaN(info.total_words_today) ? 0 : info.total_words_today) + wordNo, total_minutes_today: (isNaN(Math.round((info.total_minutes_today) * 10) / 10) ? 0 : Math.round((info.total_minutes_today) * 10) / 10) + Math.round((writingTime / 60) * 10) / 10})} text="score" total="100"/>
        </div>

    }

    return (
        <>
            <div id="writing-page"
                 className={`${display === 'none' ? 'nd-mode' : ''} ${writingStatsCssClass}`}>
                <div id="writing-area">
                    <div id="writing-inputs" className="shadow-sm">
                        <input type="text" value={writingTitle || ''} placeholder="Title"
                               autoFocus={(writingStatsUserGroup === 2 || props.isBeta) && writingId === NEW_NOTE}
                               onChange={handleWritingTitleChange}/>
                        <textarea value={writingText || ''} placeholder="Start Writing"
                                  autoFocus={(!props.isBeta && writingStatsUserGroup !== 2) || writingId !== NEW_NOTE}
                                  onKeyDown={handleKeyDown} onChange={handleWritingTextChange}/>
                    </div>
                    {sideLogo}
                </div>
                {sideLogo}

                <button id="done-writing" className="btn btn-gray" onClick={saveAndExit}>Done Writing</button>
                {renderDistractionFree()}
                {wheels}
                <span id="words-count">{wordNo} words</span>
            </div>

            <DoneWritingModal show={writingDone} stats={writingStats.current}/>
        </>
    );
};

export default EditWriting;
