import React, {useEffect, useState} from "react";
import {useAuth0} from "../react-auth0-spa";
import axios from "axios";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import Loader from "../components/Loader";

import moment from "moment";

const Admin = (props) => {
  const [fetching, setfetching] = useState(true);
  const [info, setinfo] = useState({});
  const {loading, user, getTokenSilently} = useAuth0();

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  // Make request to server store / load user data.
  useEffect(() => {
    getTokenSilently()
    .then(token =>
      axios.all([
        axios({
          url: `${process.env.REACT_APP_API_URL}/admin`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            Now: moment(new Date()).format('YYYY-MM-DD')
          },
          cancelToken: source.token
        })
      ])
    )
    .then(axios.spread((...responses) => {
      setinfo(responses[0].data.sort((a, b) => b.total_words - a.total_words))

      setfetching(false)
    }))
    .catch(error => {
      console.log(error)
    })

    return () => {
      source.cancel('Hook cleanup.');
    }
  }, [])

  if (loading || !user || fetching) {
    return <Loader />;
  }

  let getWritings = (writings) =>  {
    let rows = []

    let i = 0;
    for (let writing of writings) {
      if (writing.words > 0) {
        rows.push(<p key={i++}>
          {moment(writing.createdAt).format('YYYY, MMM DD')} | {writing.title} | {writing.words}
        </p>)
      }
    }

    return rows
  }

  let renderData = () => {
    const sections = []

    for (let user of info) {
      sections.push(
        <tr key={user.sub} id={user.sub}>
          <td>{user.name}</td>
          <td>{user.total_words}</td>
          <td>{getWritings(user.writings)}</td>
        </tr>
      )
    }

    return <table>
      <tbody>
      {sections}
      </tbody>
    </table>
  }

  const updateSendGrid = async () => {
    const token = await getTokenSilently();
    const response = await axios({
      url: `${process.env.REACT_APP_API_URL}/sendgridContacts/`,
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
          Now: moment(new Date()).format('YYYY-MM-DD')
      },
        data: {},
        cancelToken: source.token
    });

      console.log(response.data);

  }

    const updateUsersWithVisits = async () => {
        const token = await getTokenSilently();
        const response = await axios({
            url: `${process.env.REACT_APP_API_URL}/updateUsersWithVisits/`,
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                Now: moment(new Date()).format('YYYY-MM-DD')
            },
            data: {},
            cancelToken: source.token
        });

        console.log(response.data);

    }

    return (
        <>
            <div className="container-xl">
                <NavBar isBeta={props.isBeta}></NavBar>
                <input type="button" className="btn btn-gray" value="Update SendGrid" onClick={updateSendGrid}/>
                <input type="button" className="btn btn-gray" value="Update Visit Oids"
                       onClick={updateUsersWithVisits}/>
                <div className="profile-page">
                    {renderData()}
                </div>
            </div>

      <Footer></Footer>
    </>
  );
};

export default Admin;
