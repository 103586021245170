import React from "react";
import loader from "../images/loader.svg";

const Loader = () => {
  return (
    <div id="loader">
      <img height="100" src={loader} alt="Loading"/>
    </div>
  )
}

export default Loader;
