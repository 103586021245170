import React, {useState, useEffect} from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import ROUTES from "../utils/routes";
import AchievementModal from "./AchievementModal";

const AchievementBadge = (props) => {
  const [selectedAchievement, setselectedAchievement] = useState(null)

  const handleClick = (e) => {
    setselectedAchievement({
      src: props.src,
      key: props.name,
      createdAt: props.createdAt,
      name: props.name,
      info: props.info,
      disabled: props.disabled
    })
  }

  let modal
  if (selectedAchievement) {
    modal = <AchievementModal achv={selectedAchievement} dismiss={() => setselectedAchievement(null)}></AchievementModal>
  }

  return (
    <>
      <img src={props.src} alt={props.name} onClick={handleClick}/>
      {modal}
    </>
  )
}

export default AchievementBadge;
