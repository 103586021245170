const ROUTES = {
  HOME: '/',
  SETTINGS: '/settings',
  WRITING_NEW: '/writings/new',
  WRITING_EDIT: '/writings/:writingId',
  WRITINGS: '/writings',
  ADMIN: '/admin',
  ACHIEVEMENTS: '/achievements',
  CONTACT: 'https://www.writenext.io/contact'
}

export default ROUTES;
