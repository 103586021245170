import React from "react";

const StatsBox = (props) => {

  return (
    <div className="col col-12 col-md-2">
      <div className="d-flex">
        <div className={`${props.color}-border stats-box stats-box-black`}>
          <span className="digits">{props.currentValue}</span><br/>
          {props.currentText}
        </div>

        <div className={`${props.color}-bg stats-box`}>
          <span className="digits">{props.maxValue}</span><br/>
          {props.maxText}
        </div>
      </div>

      <h5 className="text-center pt-2 box-info">{props.text}</h5>
    </div>
  )
}

export default StatsBox;
