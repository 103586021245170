import React, {useEffect, useState} from "react";
import {useAuth0} from "../react-auth0-spa";
import axios from "axios";

import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import Loader from "../components/Loader";

const Settings = (props) => {
  const [fetching, setfetching] = useState(true)
  const {loading, getTokenSilently} = useAuth0();
  const [user, setuser] = useState({});

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  // Make request to server store / load user data.
  useEffect(() => {
    getTokenSilently()
    .then(token =>
      axios({
        url: `${process.env.REACT_APP_API_URL}/user`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        },
        cancelToken: source.token
      })
    )
    .then(response => {
      setuser(response.data)
      setfetching(false)
    })
    .catch(error => {
      console.log(error)
    })

    return () => {
      source.cancel('Hook cleanup.');
    }
  }, [])

  // Save on user change.
  useEffect(() => {
    if (!fetching) {
      save()
    }
  }, [user])

  if (loading || !user || fetching) {
    return <Loader />;
  }

  let save = () => {
    getTokenSilently()
    .then(token =>
      axios({
        url: `${process.env.REACT_APP_API_URL}/user`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: {
          ...user
        },
        cancelToken: source.token
      })
    )
    .then(response => {
      console.log('success')
    })
    .catch(error => {
      console.log(error)
    })
  }

  return (
    <>
      <div id="settings-page" className="container-xl">
        <NavBar isBeta={props.isBeta}></NavBar>
        <section className="pt-5">
          <h2 className="section-title">Account Settings</h2>
        </section>

        <section className="pt-5 pb-5">
          <h2 className="gray section-title mb-4">Writing Goals</h2>

          <div className="row">
            <div className="col col-md-2 text-center align-self-center">
              <label>Words per day</label><br/>
              <input type="number" className="text-center" minimum="1" value={user.daily_words || ''} onChange={(e) => {
                setuser({...user, daily_words: e.target.value})
              }}/>
            </div>

            <div className="col col-md-2">
              {/*<Circle height="120" color="#d43c67" counter="250" text="words" total={user.daily_words}/>*/}
            </div>

            <div className="col col-md-2 offset-md-3 text-center align-self-center">
              <label>Minutes per day</label><br/>
              <input type="number" className="text-center" minimum="1" value={user.daily_minutes || ''}
                     onChange={(e) => {
                       setuser({...user, daily_minutes: e.target.value})
                     }}/>
            </div>

            <div className="col col-md-2">
              {/*<Circle height="120" color="#174bc0" counter="15" text="minutes" total={user.daily_minutes}/>*/}
            </div>
          </div>
        </section>

        <section className="pt-5 pb-2">
          <h2 className="gray section-title mb-4">Email Notifications &nbsp; &nbsp; &nbsp;<span className="highlight">(coming soon)</span></h2>

          <p>
            <input type="checkbox" checked={user.daily_reminder_notification || ''} onChange={(e) => setuser({...user, daily_reminder_notification: e.target.checked})}/>
            <label>Daily Reminder</label><br/>
            <span className="checkbox-info">Great writers write daily. We can help you stay on track with a gentle reminder.</span>
          </p>

          <p>
            <input type="checkbox" checked={user.weekly_summary_notification || ''} onChange={(e) => setuser({...user, weekly_summary_notification: e.target.checked})}/>
            <label>Weekly summary</label><br/>
            <span className="checkbox-info">A weekly summary of your writing progress, goals and achievements.</span>
          </p>
        </section>

        <section className="pt-5 pb-5">
          <h2 className="gray section-title mb-4">Your Details</h2>

          <p>
            <input type="text" value={user.name || ''} onChange={(e) => setuser({...user, name: e.target.value})}/>
          </p>

          <p>
            <input type="checkbox" checked={user.private || ''} onChange={(e) => setuser({...user, private: e.target.checked})}/>
            <label>Private Account <span className="highlight">(coming soon)</span></label><br/>
            <span className="checkbox-info">Private accounts will not be able to share their Writer Profile and achievements.</span>
          </p>

          <p className="mt-5">
            <input type="button" className="btn btn-gray" value="Update" onClick={save}/>
          </p>
        </section>
      </div>

      <Footer></Footer>
    </>
  );
};

export default Settings;
