import React from "react";
import Circle from "../Circle";
import {Link, NavLink} from "react-router-dom";
import ROUTES from "../../utils/routes";
import {getScore} from "../../utils/score";

const ProfileMain = (props) => {

    const isUsingDefaultGoals = () => {
        return props.info.daily_words === 500 && props.info.daily_minutes === 60;
    }

    return (
        <section id="profile-main" className="pb-md-3 pt-md-5 pb-2 pt-5">
            <div className="row">
                <div className="col col-12 col-md-6">
                    <div className="user-info">
                        <h2 className="section-title">{props.info.name}</h2>
                        <p className="mb-0">writer
                            of {(props.info.total_words).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} words</p>
                        <p className="highlight mb-0">#{props.info.leaderboard_position} in all time leaderboard</p>
                    </div>
                    <p className="pt-4">
                        <Link to={ROUTES.WRITING_NEW} className="btn" role="button">Start Writing</Link>
                    </p>
                </div>
                <div className="col col-12 col-md-6 text-left text-md-left mt-2 mt-md-0">
                    <div className="row">
                        <div className="col">
                            <Circle height="120" color="#d43c67" counter={props.info.total_words_today} text="words"
                                    total={props.info.daily_words} />
                            <Circle height="120" color="#174bc0" counter={props.info.total_minutes_today} text="minutes"
                                    total={props.info.daily_minutes}/>
                            <Circle height="120" color="#00afb5" counter={getScore(props.info)} text="score"
                                    total="100"/>
                        </div>
                    </div>
                    <div className="row mt-0 mt-md-4">
                        <div className="col text-left text-md-left mt-2 mt-md-0">
                            <NavLink to={ROUTES.SETTINGS} exact={true}
                                     className={`btn set-goals ${isUsingDefaultGoals() ? "btn-primary" : "btn-lightgray"}`}
                                     role="button">Set Goals</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProfileMain;
