const getSplitTestingUserGroupWithDate = (userSplitTestingHexDigit, numberOfGroups, signupDate, featureStartDate) => {
    if (signupDate > featureStartDate) {
        return numberOfGroups;
    }
    return getSplitTestingUserGroup(userSplitTestingHexDigit, numberOfGroups);
}

const getSplitTestingUserGroup = (userSplitTestingHexDigit, numberOfGroups) => {
    // return 2;
    const groupSize = 16 / numberOfGroups;
    return Math.ceil((parseInt(userSplitTestingHexDigit, 16) + 1) / groupSize);
}

const getSplitTestingCssClass = (featureId, userSplitTestingHexDigit, numberOfGroups, signupDate, featureStartDate) => {
    const group = getSplitTestingUserGroupWithDate(userSplitTestingHexDigit, numberOfGroups, signupDate, featureStartDate);
    return `split-testing-f${featureId}-n${numberOfGroups}-g${group}`
}

export {getSplitTestingUserGroupWithDate, getSplitTestingUserGroup, getSplitTestingCssClass}