import React from "react";
import Circle from "../Circle";
import { Link } from "react-router-dom";
import ROUTES from "../../utils/routes";
import StatsBox from "./StatsBox";

const ProfileStats = (props) => {
  return (
    <section id="profile-main" className="pb-md-4 pt-md-5 pb-2 pt-5">
      <div className="row">
        <div className="col col-12 col-md-4">
          <h2 className="section-title gray">Writing Stats</h2>
        </div>
      </div>

      <div className="row pt-3">
        <StatsBox color="streak" text="Days Streak" currentValue={props.info.current_streak} currentText="current" maxValue={props.info.maximum_streak} maxText="max" />
        <StatsBox color="words" text="Words/Day" currentValue={props.info.avg_words_per_day} currentText="avg" maxValue={props.info.most_words_a_day} maxText="most" />
        <StatsBox color="minutes" text="Minutes/Day" currentValue={props.info.avg_minutes_per_day} currentText="avg" maxValue={props.info.most_minutes_a_day} maxText="most" />
        <StatsBox color="words" text="Words/Writing" currentValue={props.info.avg_words_per_writing} currentText="avg" maxValue={props.info.most_words_per_writing} maxText="most" />
        <StatsBox color="speed" text="Words/Minute" currentValue={props.info.avg_words_per_minute} currentText="avg" maxValue={props.info.most_words_per_minute} maxText="most" />
        <StatsBox color="minutes" text="Total Minutes" currentValue={props.info.total_writing_minutes} currentText="writing" maxValue={props.info.total_idle_minutes} maxText="idle" />
      </div>
    </section>
  )
}

export default ProfileStats;
